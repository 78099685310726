import React from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import Layout from "../../layout";
import config from "../../../data/SiteConfig";

import "./About.css";

const markdown = `
![I don't remember which mountain this is, but I'm going to say this is a picture of Mt. Rainer I took on a hike](https://i.imgur.com/B9lIDl8.jpg)


# Hi! My Name is Joseph.

Hi, my name is Joseph and I'm the creator of *Fire Budgets*. I'm a twenty-something-year-old
Software Engineer living in Seattle with a handful of different hobbies including (but not limited to) playing video games,
collecting sneakers, and eating more food than I should.   

I graduated college with no personal finance knowledge. 

My financial background consisted of me being frugal by nature and yet still losing a lot of money buying trash cryptocurrency in 2017.
**I had no budget, no savings plan, no debt repayment plan, no investment plan, nothing.** All I knew was that I wanted to buy a Tesla in cash (terrible investment) and I
wanted to get rid of my student loans as soon as possible. 

After working my first full-time job for several months it hit me. I love being a Software Engineer, but I'm spending so much of my life working. More than 50% of the hours I'm awake during the week I'm working for someone else's company. And I'm supposed to be doing this until I'm 60+ years old? No way.

That's when I decided that I should make a game plan for my financial future. I began researching how to properly save, invest, and build towards a future that didn't require me to retire in my 60s. I discovered the FIRE (Financial Independence Retire Early) movement and felt like I had all the tools I needed to create a strategy I could build upon. I'm still working on it every day,
but I have no debt and I'm currently on track to retire by the time I'm 34.

***Fire Budgets exists* so that others don't have to go through the same struggle I did to educate myself.** 

It's a site to document my financial education and progress towards early retirement, while also collecting and explaining all of my learnings in one place. If I can help just a handful of people through my personal experiences that's a win in my book.
`

function About() {
  return (
  <Layout>
    <div className="about-container">
      <Helmet title={`About | ${config.siteTitle}`} />
        <div className="about">
          {/* <img src="https://i.imgur.com/B9lIDl8.jpg" ></img> */}
          <ReactMarkdown source={markdown} className="about-text"/>
        </div>
    </div>
  </Layout>
  );
}

export default About;
